export function convertDateToVN(value) {
    const getDate = new Date(value);
    const resultDate = new Intl.DateTimeFormat("vi-VN").format(getDate);
    return resultDate;
}

export function convertTimeToVn(value) {
    const getTimeHours = new Date(value).getHours();
    const getTimeGetMinutes = new Date(value).getMinutes();
    const getTimeMinutes = String(getTimeGetMinutes).padStart(2, '0');
    return {
        hours: getTimeHours,
        minute: getTimeMinutes,
        all: getTimeHours +':'+getTimeMinutes
    };
}

export function getTimeComputer() {
    const Today = new Date();
    const getTimeHours = new Date(Today).getHours();
    const getTimeGetMinutes = new Date(Today).getMinutes();
    const getTimeMinuteString = String(getTimeGetMinutes).padStart(2, '0');
    const getTimeMinutes = Number(getTimeMinuteString);
    return {
        hours: getTimeHours,
        minute: getTimeMinutes,
        all: getTimeHours +':'+getTimeMinutes
    };
}

export function ChangeToSlug(str, styplePace) {
    var title, slug;
  
    title = String(str);
  
    slug = title.toLowerCase();
  
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //eslint-disable-next-line
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
    slug = slug.replace(/ /gi, styplePace);
    //eslint-disable-next-line
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    //eslint-disable-next-line
    slug = slug.replace(/\-\-\-\-/gi, '-');
    //eslint-disable-next-line
    slug = slug.replace(/\-\-\-/gi, '-');
    //eslint-disable-next-line
    slug = slug.replace(/\-\-/gi, '-');
    slug = '@' + slug + '@';
    //eslint-disable-next-line
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    return slug;
  }