export const URL = '/api';
export const RAW_JSON =  { 
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
};

// api
export const GET_SITE_NAME = '/RAU01/Process';
export const SWITCH_SITE = '/USI01/Process';
export const GET_BOOKING_DETAILS = '/course/UCAC03/Process';
export const GET_INFO_DETAILS_BDC = '/course/RBK02B/Process';
export const GET_TOKEN_LOGIN = '/RAT01/Process';
export const SEARCH_LIST_SHIFT = '/course/RCAC04/process';
export const GET_PROFILE = '/course/RCA02/Process';
export const GET_LANGUAGES = '/course/RCOM02/Process'
export const LIST_LANGUAGE = '/course/RLA01/Process';
export const INPUT_BAGTAG_CODE = '/course/UBK10/Process';
export const API_ICI01 = '/course/ICI01/Process';