import routes from '@/router/index.js';
import store from '@/store/store.js';
import { switchSiteName } from '@/api/login.js';
import { i18n, loadLocaleMessages } from '@/libs/i18n';
import EzGolfDropdown from '@/components/EzGolfDropdown/EzGolfDropdown.vue';
import ModalBox from '@/components/ModalBox/ModalBox.vue';


export default {
    name: 'App',
    components: {
        EzGolfDropdown,
        ModalBox,
    },
    data(){
        return {
            hasShowMenu: false,
            dataSite: JSON.parse(localStorage.getItem('SiteData')),
            dataPickMenuDropdown: {},
            isShowModalPickSite: false,
        };
    },
    setup() {
        const stores = store; 
        const getObjectToken =  JSON.parse(localStorage.getItem('TOKEN_RAT01'));
        return {stores, getObjectToken};
      },
    methods: {
        activeMenuTop() {
            this.hasShowMenu = true;
        },

        deActiveMenuTop() {
            this.hasShowMenu = false;
        },

        logOut() {
            const dataRemove = ['AccessToken', 'CaddyId', 'TOKEN_RAT01', 'SiteData'];
            dataRemove.forEach((item) => localStorage.removeItem(item));
            routes.push('/');
            this.deActiveMenuTop();
            store.commit('STATES_LOGIN', false);
        },

       async pickMenuDropdown(value) {
            this.dataPickMenuDropdown = value;
        },

        async acceptPickSiteName() {
            const param = {
                'SiteId': this.dataPickMenuDropdown.SiteId
            };
            const resSiteNamePicked = await switchSiteName(param);
            localStorage.setItem('AccessToken', resSiteNamePicked.data.Data.AccessToken);
            localStorage.setItem('SiteId', this.dataPickMenuDropdown.SiteId);
            this.isShowModalPickSite = false;
            routes.push('/');
        },

        showModalPickSite() {
            this.isShowModalPickSite = true;
        },
        async triggerPickMenu() {
            const siteIdSaved = localStorage.getItem('SiteId');
            this.dataSite.forEach((item) => {
                if (item.SiteId === siteIdSaved) {
                    this.dataPickMenuDropdown = item;
                } else {
                    this.dataPickMenuDropdown = this.dataSite[0];
                }
            });
        },
        changeLanguage(value) {
           localStorage.setItem('systemLanguage', value.WordTranslate);
           i18n.locale = value.WordTranslate;
           loadLocaleMessages();
        },
        setTransalte() {
            console.log('listLangues', this.listLangues);
            const systemLanguage = localStorage.getItem('systemLanguage') || null;
            i18n.locale = systemLanguage ? this.listLangues[0].WordTranslate : systemLanguage;
            loadLocaleMessages();
        }

    },
    computed: {
        listLangues() {
            return this.stores.state.arrayListLangues;
        }
    },
    mounted() {
        // this.setTransalte();
        this.triggerPickMenu();
    }
}