import router from '@/router/index.js';
import store from '@/store/store.js';

export function notLogin() {
  router.push({ name: 'LoginAuthentication'});
  return;
}
export function handleCheckLogin(value) {
  store.commit('STATES_LOADING', false);
  if(value.data.Status !== '200') {
    store.commit('STATES_MESSAGE_REPORT', value.data.Messages[0]);
    const searchC401 = value.data.Messages[0].Code.search('C401');
    if (searchC401 > -1) {
      const dataRemove = ['AccessToken', 'CaddyId', 'TOKEN_RAT01', 'SiteData'];
      dataRemove.forEach((item) => localStorage.removeItem(item));
      notLogin();
    }
    return value;
  }
  return value;
}