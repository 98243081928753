import { render, staticRenderFns } from "./NofticationReport.vue?vue&type=template&id=08c7547d&scoped=true"
import script from "./NofticationReport.js?vue&type=script&lang=js&external"
export * from "./NofticationReport.js?vue&type=script&lang=js&external"
import style0 from "./NofticationReport.vue?vue&type=style&index=0&id=08c7547d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c7547d",
  null
  
)

export default component.exports