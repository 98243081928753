/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getTranslate, getLanguages } from '@/api/service.js';
import store from '@/store/store.js';
import en_gb from './locales/en.json';
import vi_vn from './locales/vi.json';

Vue.use(VueI18n)

let locale = localStorage.getItem('systemLanguage') || null; // default locale

if (!locale) {
  localStorage.setItem('systemLanguage', '1000000');
  locale = localStorage.getItem('systemLanguage');
}

let messages = {
  en_gb: {
    ...en_gb,
  },
  vi_vn: {
    ...vi_vn,
  },
};

const stores = store;

async function fetchTranslate() {
  const param = {
    'KeyGroup': "WEB_APP_CADDY"
  }
  const res = await getTranslate(param);
  const convertToObject = JSON.parse(res.data.Data);
  return convertToObject;
}

async function fetchLanguages() {
  const res = await getLanguages();
  if (res.data.Data.LangCategory) {
      res.data.Data.LangCategory.map((item) => item.Img = require(`@/assets/${item.WordTranslate}.svg`));
      store.commit('SET_LANGUEGES', res.data.Data.LangCategory.filter((item) => item.Active));
    }
    return res.data.Data.LangCategory.filter((item) => item.Active);
}

export const i18n = new VueI18n({
  locale,
  fallbackLocale: locale,
  messages
});

export async function loadLocaleMessages() {
  store.commit('STATES_LOADING', true);
  const getLanguages = await fetchLanguages();
  const getTranslate = await fetchTranslate();
  if (!getTranslate) {
    return;
  }
  messages = {};
  getLanguages.forEach((item) => {
    i18n.setLocaleMessage(item.WordTranslate, getTranslate[item.LangId]);
  });
}

