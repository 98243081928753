export default {
    name:'InputForm',
    props: {
      placeholder: {
        type: String,
        default: 'placeholder'
      },
      type: {
        type: String,
        default: 'text'
      },
      text: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
      }
    },
    watch: {
      text(value) {
        this.$emit('update:text', value);
      }
    }
  }