
export default {
    name: 'LoadingBackground',
    props: {
        isShowPopup: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        };
    },
    methods: {

    },
}