import NofticationItem from '@/components/NofticationItem/NofticationItem.vue';

export default {
    name: 'NofticationBooking',
    components: {
        NofticationItem
    },
    data(){
        return{};
    }
}